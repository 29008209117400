import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0e433b93 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _fb915068 = () => interopDefault(import('../pages/conditions-of-use.vue' /* webpackChunkName: "pages/conditions-of-use" */))
const _e64111b4 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _73e24fc1 = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _3264d46c = () => interopDefault(import('../pages/explorer/index.vue' /* webpackChunkName: "pages/explorer/index" */))
const _a8eac834 = () => interopDefault(import('../pages/onpiste-app.vue' /* webpackChunkName: "pages/onpiste-app" */))
const _1b596bfe = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _599e11dd = () => interopDefault(import('../pages/profile/index.js' /* webpackChunkName: "pages/profile/index" */))
const _565c2c68 = () => interopDefault(import('../pages/respect.vue' /* webpackChunkName: "pages/respect" */))
const _016c6b82 = () => interopDefault(import('../pages/settings/index.js' /* webpackChunkName: "pages/settings/index" */))
const _34780b0c = () => interopDefault(import('../pages/signin.vue' /* webpackChunkName: "pages/signin" */))
const _18f50744 = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _5d872133 = () => interopDefault(import('../pages/account/email-confirm.vue' /* webpackChunkName: "pages/account/email-confirm" */))
const _ce4299fc = () => interopDefault(import('../pages/account/email-reset.vue' /* webpackChunkName: "pages/account/email-reset" */))
const _35b6398c = () => interopDefault(import('../pages/account/password-lost.vue' /* webpackChunkName: "pages/account/password-lost" */))
const _146764a9 = () => interopDefault(import('../pages/account/password-reset.vue' /* webpackChunkName: "pages/account/password-reset" */))
const _796678a8 = () => interopDefault(import('../pages/profile/my-runs/index.vue' /* webpackChunkName: "pages/profile/my-runs/index" */))
const _4f3345ab = () => interopDefault(import('../pages/settings/account.vue' /* webpackChunkName: "pages/settings/account" */))
const _cf174dcc = () => interopDefault(import('../pages/settings/email.vue' /* webpackChunkName: "pages/settings/email" */))
const _262a2727 = () => interopDefault(import('../pages/settings/profile.vue' /* webpackChunkName: "pages/settings/profile" */))
const _bb862762 = () => interopDefault(import('../pages/signup/email.vue' /* webpackChunkName: "pages/signup/email" */))
const _ec36d388 = () => interopDefault(import('../pages/signup/experiences.vue' /* webpackChunkName: "pages/signup/experiences" */))
const _a76f7138 = () => interopDefault(import('../pages/signup/informations.vue' /* webpackChunkName: "pages/signup/informations" */))
const _3fca7dc8 = () => interopDefault(import('../pages/signup/profile.vue' /* webpackChunkName: "pages/signup/profile" */))
const _120d541e = () => interopDefault(import('../pages/explorer/destinations/_destinationid/index.vue' /* webpackChunkName: "pages/explorer/destinations/_destinationid/index" */))
const _477ba249 = () => interopDefault(import('../pages/explorer/events/_eventid/index.vue' /* webpackChunkName: "pages/explorer/events/_eventid/index" */))
const _040965a7 = () => interopDefault(import('../pages/explorer/routes/_routeid/index.vue' /* webpackChunkName: "pages/explorer/routes/_routeid/index" */))
const _6263f383 = () => interopDefault(import('../pages/explorer/service-providers/_providerid/index.vue' /* webpackChunkName: "pages/explorer/service-providers/_providerid/index" */))
const _8233818c = () => interopDefault(import('../pages/explorer/tourist-offices/_touristid/index.vue' /* webpackChunkName: "pages/explorer/tourist-offices/_touristid/index" */))
const _823a1280 = () => interopDefault(import('../pages/profile/my-runs/_runid/index.vue' /* webpackChunkName: "pages/profile/my-runs/_runid/index" */))
const _c9fb1b0a = () => interopDefault(import('../pages/explorer/destinations/_destinationid/offer.vue' /* webpackChunkName: "pages/explorer/destinations/_destinationid/offer" */))
const _6de0ebfc = () => interopDefault(import('../pages/explorer/routes/_routeid/print.vue' /* webpackChunkName: "pages/explorer/routes/_routeid/print" */))
const _4a8156e8 = () => interopDefault(import('../pages/profile/my-runs/_runid/edit.vue' /* webpackChunkName: "pages/profile/my-runs/_runid/edit" */))
const _349fdedc = () => interopDefault(import('../pages/profile/runs/_runid/_previewToken/index.vue' /* webpackChunkName: "pages/profile/runs/_runid/_previewToken/index" */))
const _0c8cbcf8 = () => interopDefault(import('../pages/universes/_universeid.vue' /* webpackChunkName: "pages/universes/_universeid" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fr___default"
  }, {
    path: "/ca",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___ca"
  }, {
    path: "/conditions-of-use",
    component: _fb915068,
    pathToRegexpOptions: {"strict":true},
    name: "conditions-of-use___fr___default"
  }, {
    path: "/contact",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr___default"
  }, {
    path: "/cookie-policy",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___fr___default"
  }, {
    path: "/en",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/es",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/explorer",
    component: _3264d46c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer___fr___default"
  }, {
    path: "/fr",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/it",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___it"
  }, {
    path: "/onpiste-app",
    component: _a8eac834,
    pathToRegexpOptions: {"strict":true},
    name: "onpiste-app___fr___default"
  }, {
    path: "/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fr___default"
  }, {
    path: "/profile",
    component: _599e11dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fr___default"
  }, {
    path: "/respect",
    component: _565c2c68,
    pathToRegexpOptions: {"strict":true},
    name: "respect___fr___default"
  }, {
    path: "/settings",
    component: _016c6b82,
    pathToRegexpOptions: {"strict":true},
    name: "settings___fr___default"
  }, {
    path: "/signin",
    component: _34780b0c,
    pathToRegexpOptions: {"strict":true},
    name: "signin___fr___default"
  }, {
    path: "/terms-and-conditions",
    component: _18f50744,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___fr___default"
  }, {
    path: "/account/email-confirm",
    component: _5d872133,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-confirm___fr___default"
  }, {
    path: "/account/email-reset",
    component: _ce4299fc,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-reset___fr___default"
  }, {
    path: "/account/password-lost",
    component: _35b6398c,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-lost___fr___default"
  }, {
    path: "/account/password-reset",
    component: _146764a9,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-reset___fr___default"
  }, {
    path: "/ca/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___ca"
  }, {
    path: "/ca/conditions-of-use",
    component: _fb915068,
    pathToRegexpOptions: {"strict":true},
    name: "conditions-of-use___ca"
  }, {
    path: "/ca/contact",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___ca"
  }, {
    path: "/ca/cookie-policy",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___ca"
  }, {
    path: "/ca/explorer",
    component: _3264d46c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer___ca"
  }, {
    path: "/ca/onpiste-app",
    component: _a8eac834,
    pathToRegexpOptions: {"strict":true},
    name: "onpiste-app___ca"
  }, {
    path: "/ca/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___ca"
  }, {
    path: "/ca/profile",
    component: _599e11dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile___ca"
  }, {
    path: "/ca/respect",
    component: _565c2c68,
    pathToRegexpOptions: {"strict":true},
    name: "respect___ca"
  }, {
    path: "/ca/settings",
    component: _016c6b82,
    pathToRegexpOptions: {"strict":true},
    name: "settings___ca"
  }, {
    path: "/ca/signin",
    component: _34780b0c,
    pathToRegexpOptions: {"strict":true},
    name: "signin___ca"
  }, {
    path: "/ca/terms-and-conditions",
    component: _18f50744,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___ca"
  }, {
    path: "/en/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___en"
  }, {
    path: "/en/conditions-of-use",
    component: _fb915068,
    pathToRegexpOptions: {"strict":true},
    name: "conditions-of-use___en"
  }, {
    path: "/en/contact",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/en/cookie-policy",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___en"
  }, {
    path: "/en/explorer",
    component: _3264d46c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer___en"
  }, {
    path: "/en/onpiste-app",
    component: _a8eac834,
    pathToRegexpOptions: {"strict":true},
    name: "onpiste-app___en"
  }, {
    path: "/en/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___en"
  }, {
    path: "/en/profile",
    component: _599e11dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/en/respect",
    component: _565c2c68,
    pathToRegexpOptions: {"strict":true},
    name: "respect___en"
  }, {
    path: "/en/settings",
    component: _016c6b82,
    pathToRegexpOptions: {"strict":true},
    name: "settings___en"
  }, {
    path: "/en/signin",
    component: _34780b0c,
    pathToRegexpOptions: {"strict":true},
    name: "signin___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _18f50744,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___en"
  }, {
    path: "/es/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___es"
  }, {
    path: "/es/conditions-of-use",
    component: _fb915068,
    pathToRegexpOptions: {"strict":true},
    name: "conditions-of-use___es"
  }, {
    path: "/es/contact",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___es"
  }, {
    path: "/es/cookie-policy",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___es"
  }, {
    path: "/es/explorer",
    component: _3264d46c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer___es"
  }, {
    path: "/es/onpiste-app",
    component: _a8eac834,
    pathToRegexpOptions: {"strict":true},
    name: "onpiste-app___es"
  }, {
    path: "/es/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___es"
  }, {
    path: "/es/profile",
    component: _599e11dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile___es"
  }, {
    path: "/es/respect",
    component: _565c2c68,
    pathToRegexpOptions: {"strict":true},
    name: "respect___es"
  }, {
    path: "/es/settings",
    component: _016c6b82,
    pathToRegexpOptions: {"strict":true},
    name: "settings___es"
  }, {
    path: "/es/signin",
    component: _34780b0c,
    pathToRegexpOptions: {"strict":true},
    name: "signin___es"
  }, {
    path: "/es/terms-and-conditions",
    component: _18f50744,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___es"
  }, {
    path: "/fr/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___fr"
  }, {
    path: "/fr/conditions-of-use",
    component: _fb915068,
    pathToRegexpOptions: {"strict":true},
    name: "conditions-of-use___fr"
  }, {
    path: "/fr/contact",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr"
  }, {
    path: "/fr/cookie-policy",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___fr"
  }, {
    path: "/fr/explorer",
    component: _3264d46c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer___fr"
  }, {
    path: "/fr/onpiste-app",
    component: _a8eac834,
    pathToRegexpOptions: {"strict":true},
    name: "onpiste-app___fr"
  }, {
    path: "/fr/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___fr"
  }, {
    path: "/fr/profile",
    component: _599e11dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile___fr"
  }, {
    path: "/fr/respect",
    component: _565c2c68,
    pathToRegexpOptions: {"strict":true},
    name: "respect___fr"
  }, {
    path: "/fr/settings",
    component: _016c6b82,
    pathToRegexpOptions: {"strict":true},
    name: "settings___fr"
  }, {
    path: "/fr/signin",
    component: _34780b0c,
    pathToRegexpOptions: {"strict":true},
    name: "signin___fr"
  }, {
    path: "/fr/terms-and-conditions",
    component: _18f50744,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___fr"
  }, {
    path: "/it/about",
    component: _0e433b93,
    pathToRegexpOptions: {"strict":true},
    name: "about___it"
  }, {
    path: "/it/conditions-of-use",
    component: _fb915068,
    pathToRegexpOptions: {"strict":true},
    name: "conditions-of-use___it"
  }, {
    path: "/it/contact",
    component: _e64111b4,
    pathToRegexpOptions: {"strict":true},
    name: "contact___it"
  }, {
    path: "/it/cookie-policy",
    component: _73e24fc1,
    pathToRegexpOptions: {"strict":true},
    name: "cookie-policy___it"
  }, {
    path: "/it/explorer",
    component: _3264d46c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer___it"
  }, {
    path: "/it/onpiste-app",
    component: _a8eac834,
    pathToRegexpOptions: {"strict":true},
    name: "onpiste-app___it"
  }, {
    path: "/it/privacy-policy",
    component: _1b596bfe,
    pathToRegexpOptions: {"strict":true},
    name: "privacy-policy___it"
  }, {
    path: "/it/profile",
    component: _599e11dd,
    pathToRegexpOptions: {"strict":true},
    name: "profile___it"
  }, {
    path: "/it/respect",
    component: _565c2c68,
    pathToRegexpOptions: {"strict":true},
    name: "respect___it"
  }, {
    path: "/it/settings",
    component: _016c6b82,
    pathToRegexpOptions: {"strict":true},
    name: "settings___it"
  }, {
    path: "/it/signin",
    component: _34780b0c,
    pathToRegexpOptions: {"strict":true},
    name: "signin___it"
  }, {
    path: "/it/terms-and-conditions",
    component: _18f50744,
    pathToRegexpOptions: {"strict":true},
    name: "terms-and-conditions___it"
  }, {
    path: "/profile/my-runs",
    component: _796678a8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs___fr___default"
  }, {
    path: "/settings/account",
    component: _4f3345ab,
    pathToRegexpOptions: {"strict":true},
    name: "settings-account___fr___default"
  }, {
    path: "/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email___fr___default"
  }, {
    path: "/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile___fr___default"
  }, {
    path: "/signup/email",
    component: _bb862762,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email___fr___default"
  }, {
    path: "/signup/experiences",
    component: _ec36d388,
    pathToRegexpOptions: {"strict":true},
    name: "signup-experiences___fr___default"
  }, {
    path: "/signup/informations",
    component: _a76f7138,
    pathToRegexpOptions: {"strict":true},
    name: "signup-informations___fr___default"
  }, {
    path: "/signup/profile",
    component: _3fca7dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-profile___fr___default"
  }, {
    path: "/ca/account/email-confirm",
    component: _5d872133,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-confirm___ca"
  }, {
    path: "/ca/account/email-reset",
    component: _ce4299fc,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-reset___ca"
  }, {
    path: "/ca/account/password-lost",
    component: _35b6398c,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-lost___ca"
  }, {
    path: "/ca/account/password-reset",
    component: _146764a9,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-reset___ca"
  }, {
    path: "/ca/profile/my-runs",
    component: _796678a8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs___ca"
  }, {
    path: "/ca/settings/account",
    component: _4f3345ab,
    pathToRegexpOptions: {"strict":true},
    name: "settings-account___ca"
  }, {
    path: "/ca/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email___ca"
  }, {
    path: "/ca/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile___ca"
  }, {
    path: "/ca/signup/email",
    component: _bb862762,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email___ca"
  }, {
    path: "/ca/signup/experiences",
    component: _ec36d388,
    pathToRegexpOptions: {"strict":true},
    name: "signup-experiences___ca"
  }, {
    path: "/ca/signup/informations",
    component: _a76f7138,
    pathToRegexpOptions: {"strict":true},
    name: "signup-informations___ca"
  }, {
    path: "/ca/signup/profile",
    component: _3fca7dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-profile___ca"
  }, {
    path: "/en/account/email-confirm",
    component: _5d872133,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-confirm___en"
  }, {
    path: "/en/account/email-reset",
    component: _ce4299fc,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-reset___en"
  }, {
    path: "/en/account/password-lost",
    component: _35b6398c,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-lost___en"
  }, {
    path: "/en/account/password-reset",
    component: _146764a9,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-reset___en"
  }, {
    path: "/en/profile/my-runs",
    component: _796678a8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs___en"
  }, {
    path: "/en/settings/account",
    component: _4f3345ab,
    pathToRegexpOptions: {"strict":true},
    name: "settings-account___en"
  }, {
    path: "/en/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email___en"
  }, {
    path: "/en/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile___en"
  }, {
    path: "/en/signup/email",
    component: _bb862762,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email___en"
  }, {
    path: "/en/signup/experiences",
    component: _ec36d388,
    pathToRegexpOptions: {"strict":true},
    name: "signup-experiences___en"
  }, {
    path: "/en/signup/informations",
    component: _a76f7138,
    pathToRegexpOptions: {"strict":true},
    name: "signup-informations___en"
  }, {
    path: "/en/signup/profile",
    component: _3fca7dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-profile___en"
  }, {
    path: "/es/account/email-confirm",
    component: _5d872133,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-confirm___es"
  }, {
    path: "/es/account/email-reset",
    component: _ce4299fc,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-reset___es"
  }, {
    path: "/es/account/password-lost",
    component: _35b6398c,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-lost___es"
  }, {
    path: "/es/account/password-reset",
    component: _146764a9,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-reset___es"
  }, {
    path: "/es/profile/my-runs",
    component: _796678a8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs___es"
  }, {
    path: "/es/settings/account",
    component: _4f3345ab,
    pathToRegexpOptions: {"strict":true},
    name: "settings-account___es"
  }, {
    path: "/es/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email___es"
  }, {
    path: "/es/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile___es"
  }, {
    path: "/es/signup/email",
    component: _bb862762,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email___es"
  }, {
    path: "/es/signup/experiences",
    component: _ec36d388,
    pathToRegexpOptions: {"strict":true},
    name: "signup-experiences___es"
  }, {
    path: "/es/signup/informations",
    component: _a76f7138,
    pathToRegexpOptions: {"strict":true},
    name: "signup-informations___es"
  }, {
    path: "/es/signup/profile",
    component: _3fca7dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-profile___es"
  }, {
    path: "/fr/account/email-confirm",
    component: _5d872133,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-confirm___fr"
  }, {
    path: "/fr/account/email-reset",
    component: _ce4299fc,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-reset___fr"
  }, {
    path: "/fr/account/password-lost",
    component: _35b6398c,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-lost___fr"
  }, {
    path: "/fr/account/password-reset",
    component: _146764a9,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-reset___fr"
  }, {
    path: "/fr/profile/my-runs",
    component: _796678a8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs___fr"
  }, {
    path: "/fr/settings/account",
    component: _4f3345ab,
    pathToRegexpOptions: {"strict":true},
    name: "settings-account___fr"
  }, {
    path: "/fr/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email___fr"
  }, {
    path: "/fr/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile___fr"
  }, {
    path: "/fr/signup/email",
    component: _bb862762,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email___fr"
  }, {
    path: "/fr/signup/experiences",
    component: _ec36d388,
    pathToRegexpOptions: {"strict":true},
    name: "signup-experiences___fr"
  }, {
    path: "/fr/signup/informations",
    component: _a76f7138,
    pathToRegexpOptions: {"strict":true},
    name: "signup-informations___fr"
  }, {
    path: "/fr/signup/profile",
    component: _3fca7dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-profile___fr"
  }, {
    path: "/it/account/email-confirm",
    component: _5d872133,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-confirm___it"
  }, {
    path: "/it/account/email-reset",
    component: _ce4299fc,
    pathToRegexpOptions: {"strict":true},
    name: "account-email-reset___it"
  }, {
    path: "/it/account/password-lost",
    component: _35b6398c,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-lost___it"
  }, {
    path: "/it/account/password-reset",
    component: _146764a9,
    pathToRegexpOptions: {"strict":true},
    name: "account-password-reset___it"
  }, {
    path: "/it/profile/my-runs",
    component: _796678a8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs___it"
  }, {
    path: "/it/settings/account",
    component: _4f3345ab,
    pathToRegexpOptions: {"strict":true},
    name: "settings-account___it"
  }, {
    path: "/it/settings/email",
    component: _cf174dcc,
    pathToRegexpOptions: {"strict":true},
    name: "settings-email___it"
  }, {
    path: "/it/settings/profile",
    component: _262a2727,
    pathToRegexpOptions: {"strict":true},
    name: "settings-profile___it"
  }, {
    path: "/it/signup/email",
    component: _bb862762,
    pathToRegexpOptions: {"strict":true},
    name: "signup-email___it"
  }, {
    path: "/it/signup/experiences",
    component: _ec36d388,
    pathToRegexpOptions: {"strict":true},
    name: "signup-experiences___it"
  }, {
    path: "/it/signup/informations",
    component: _a76f7138,
    pathToRegexpOptions: {"strict":true},
    name: "signup-informations___it"
  }, {
    path: "/it/signup/profile",
    component: _3fca7dc8,
    pathToRegexpOptions: {"strict":true},
    name: "signup-profile___it"
  }, {
    path: "/ca/explorer/destinations/:destinationid",
    component: _120d541e,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid___ca"
  }, {
    path: "/ca/explorer/events/:eventid",
    component: _477ba249,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-events-eventid___ca"
  }, {
    path: "/ca/explorer/routes/:routeid",
    component: _040965a7,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid___ca"
  }, {
    path: "/ca/explorer/service-providers/:providerid",
    component: _6263f383,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-service-providers-providerid___ca"
  }, {
    path: "/ca/explorer/tourist-offices/:touristid",
    component: _8233818c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-tourist-offices-touristid___ca"
  }, {
    path: "/ca/profile/my-runs/:runid",
    component: _823a1280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid___ca"
  }, {
    path: "/en/explorer/destinations/:destinationid",
    component: _120d541e,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid___en"
  }, {
    path: "/en/explorer/events/:eventid",
    component: _477ba249,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-events-eventid___en"
  }, {
    path: "/en/explorer/routes/:routeid",
    component: _040965a7,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid___en"
  }, {
    path: "/en/explorer/service-providers/:providerid",
    component: _6263f383,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-service-providers-providerid___en"
  }, {
    path: "/en/explorer/tourist-offices/:touristid",
    component: _8233818c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-tourist-offices-touristid___en"
  }, {
    path: "/en/profile/my-runs/:runid",
    component: _823a1280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid___en"
  }, {
    path: "/es/explorer/destinations/:destinationid",
    component: _120d541e,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid___es"
  }, {
    path: "/es/explorer/events/:eventid",
    component: _477ba249,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-events-eventid___es"
  }, {
    path: "/es/explorer/routes/:routeid",
    component: _040965a7,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid___es"
  }, {
    path: "/es/explorer/service-providers/:providerid",
    component: _6263f383,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-service-providers-providerid___es"
  }, {
    path: "/es/explorer/tourist-offices/:touristid",
    component: _8233818c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-tourist-offices-touristid___es"
  }, {
    path: "/es/profile/my-runs/:runid",
    component: _823a1280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid___es"
  }, {
    path: "/fr/explorer/destinations/:destinationid",
    component: _120d541e,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid___fr"
  }, {
    path: "/fr/explorer/events/:eventid",
    component: _477ba249,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-events-eventid___fr"
  }, {
    path: "/fr/explorer/routes/:routeid",
    component: _040965a7,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid___fr"
  }, {
    path: "/fr/explorer/service-providers/:providerid",
    component: _6263f383,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-service-providers-providerid___fr"
  }, {
    path: "/fr/explorer/tourist-offices/:touristid",
    component: _8233818c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-tourist-offices-touristid___fr"
  }, {
    path: "/fr/profile/my-runs/:runid",
    component: _823a1280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid___fr"
  }, {
    path: "/it/explorer/destinations/:destinationid",
    component: _120d541e,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid___it"
  }, {
    path: "/it/explorer/events/:eventid",
    component: _477ba249,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-events-eventid___it"
  }, {
    path: "/it/explorer/routes/:routeid",
    component: _040965a7,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid___it"
  }, {
    path: "/it/explorer/service-providers/:providerid",
    component: _6263f383,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-service-providers-providerid___it"
  }, {
    path: "/it/explorer/tourist-offices/:touristid",
    component: _8233818c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-tourist-offices-touristid___it"
  }, {
    path: "/it/profile/my-runs/:runid",
    component: _823a1280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid___it"
  }, {
    path: "/ca/explorer/destinations/:destinationid?/offer",
    component: _c9fb1b0a,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid-offer___ca"
  }, {
    path: "/ca/explorer/routes/:routeid?/print",
    component: _6de0ebfc,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid-print___ca"
  }, {
    path: "/ca/profile/my-runs/:runid?/edit",
    component: _4a8156e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid-edit___ca"
  }, {
    path: "/en/explorer/destinations/:destinationid?/offer",
    component: _c9fb1b0a,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid-offer___en"
  }, {
    path: "/en/explorer/routes/:routeid?/print",
    component: _6de0ebfc,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid-print___en"
  }, {
    path: "/en/profile/my-runs/:runid?/edit",
    component: _4a8156e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid-edit___en"
  }, {
    path: "/es/explorer/destinations/:destinationid?/offer",
    component: _c9fb1b0a,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid-offer___es"
  }, {
    path: "/es/explorer/routes/:routeid?/print",
    component: _6de0ebfc,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid-print___es"
  }, {
    path: "/es/profile/my-runs/:runid?/edit",
    component: _4a8156e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid-edit___es"
  }, {
    path: "/fr/explorer/destinations/:destinationid?/offer",
    component: _c9fb1b0a,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid-offer___fr"
  }, {
    path: "/fr/explorer/routes/:routeid?/print",
    component: _6de0ebfc,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid-print___fr"
  }, {
    path: "/fr/profile/my-runs/:runid?/edit",
    component: _4a8156e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid-edit___fr"
  }, {
    path: "/it/explorer/destinations/:destinationid?/offer",
    component: _c9fb1b0a,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid-offer___it"
  }, {
    path: "/it/explorer/routes/:routeid?/print",
    component: _6de0ebfc,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid-print___it"
  }, {
    path: "/it/profile/my-runs/:runid?/edit",
    component: _4a8156e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid-edit___it"
  }, {
    path: "/ca/profile/runs/:runid?/:previewToken",
    component: _349fdedc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-runs-runid-previewToken___ca"
  }, {
    path: "/en/profile/runs/:runid?/:previewToken",
    component: _349fdedc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-runs-runid-previewToken___en"
  }, {
    path: "/es/profile/runs/:runid?/:previewToken",
    component: _349fdedc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-runs-runid-previewToken___es"
  }, {
    path: "/fr/profile/runs/:runid?/:previewToken",
    component: _349fdedc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-runs-runid-previewToken___fr"
  }, {
    path: "/it/profile/runs/:runid?/:previewToken",
    component: _349fdedc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-runs-runid-previewToken___it"
  }, {
    path: "/ca/universes/:universeid?",
    component: _0c8cbcf8,
    pathToRegexpOptions: {"strict":true},
    name: "universes-universeid___ca"
  }, {
    path: "/en/universes/:universeid?",
    component: _0c8cbcf8,
    pathToRegexpOptions: {"strict":true},
    name: "universes-universeid___en"
  }, {
    path: "/es/universes/:universeid?",
    component: _0c8cbcf8,
    pathToRegexpOptions: {"strict":true},
    name: "universes-universeid___es"
  }, {
    path: "/explorer/destinations/:destinationid",
    component: _120d541e,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid___fr___default"
  }, {
    path: "/explorer/events/:eventid",
    component: _477ba249,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-events-eventid___fr___default"
  }, {
    path: "/explorer/routes/:routeid",
    component: _040965a7,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid___fr___default"
  }, {
    path: "/explorer/service-providers/:providerid",
    component: _6263f383,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-service-providers-providerid___fr___default"
  }, {
    path: "/explorer/tourist-offices/:touristid",
    component: _8233818c,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-tourist-offices-touristid___fr___default"
  }, {
    path: "/fr/universes/:universeid?",
    component: _0c8cbcf8,
    pathToRegexpOptions: {"strict":true},
    name: "universes-universeid___fr"
  }, {
    path: "/it/universes/:universeid?",
    component: _0c8cbcf8,
    pathToRegexpOptions: {"strict":true},
    name: "universes-universeid___it"
  }, {
    path: "/profile/my-runs/:runid",
    component: _823a1280,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid___fr___default"
  }, {
    path: "/explorer/destinations/:destinationid?/offer",
    component: _c9fb1b0a,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-destinations-destinationid-offer___fr___default"
  }, {
    path: "/explorer/routes/:routeid?/print",
    component: _6de0ebfc,
    pathToRegexpOptions: {"strict":true},
    name: "explorer-routes-routeid-print___fr___default"
  }, {
    path: "/profile/my-runs/:runid?/edit",
    component: _4a8156e8,
    pathToRegexpOptions: {"strict":true},
    name: "profile-my-runs-runid-edit___fr___default"
  }, {
    path: "/profile/runs/:runid?/:previewToken",
    component: _349fdedc,
    pathToRegexpOptions: {"strict":true},
    name: "profile-runs-runid-previewToken___fr___default"
  }, {
    path: "/universes/:universeid?",
    component: _0c8cbcf8,
    pathToRegexpOptions: {"strict":true},
    name: "universes-universeid___fr___default"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
