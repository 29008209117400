import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { objectIsEmpty } from '~/utils/common';

import { activationError, validationError } from "~/utils/errors";

// =================================== STATE ===================================

const initLoginForm = (): Types.LoginForm => ({
  provider: undefined,
  social_user_id: "",
  social_token: "",
  email: "",
  password: "",
  firstname: "",
  lastname: "",
});

export const state: Types.UsersState = () => ({
  me: {},
  loginForm: initLoginForm(),
  userIsLoaded: false,
  signLayoutImage: '',
  error: null,
  newsletterHasBeenSeen: false,
});

export type RootState = ReturnType<typeof state>

// ================================== GETTERS ==================================

export const getters: GetterTree<RootState, RootState> = {
};

// ================================= MUTATIONS =================================

// TODO: move this to utils (need refactor to move utils to ts files)
function updateObject<T> (obj: T, other: Partial<T>) {
  for (const key in other) {
    if (key in obj) {
      // @ts-ignore: ... is not assignable ...
      obj[key] = other[key];
    }
  }
}

export const mutations: MutationTree<RootState> = {
  SET_ME (state, userData: Object) {
    state.me = userData || null;
  },

  SET_USER_IS_LOADING (state, payload: boolean) {
    state.userIsLoaded = payload;
  },

  SET_LOGIN_FORM (state, payload?: Partial<Types.LoginForm>) {
    if (!payload) {
      state.loginForm = initLoginForm();
    } else {
      updateObject(state.loginForm, payload);
    }
  },

  SET_SIGN_IMAGE (state, payload: string) {
    state.signLayoutImage = payload;
  },

  SET_LOGIN_ERROR (state, payload: any) {
    state.error = payload;
  },

  STORE_NEWSLETTER_HAS_BEEN_SEEN (state, payload: boolean) {
    state.newsletterHasBeenSeen = payload;
  },
};

// ================================== ACTIONS ==================================

export const actions: ActionTree<RootState, RootState> = {
  _reset (ctx) {
    for (const MUTATION in mutations) {
      if (MUTATION.startsWith('SET_')) ctx.commit(MUTATION, undefined);
    }
  },

  async signinWithEmail (ctx, body: Types.SignInEmailPayload) {
    const { data } = await this.$axios.post(`/users/signin/email?lang=${ctx.rootGetters['lang/getLocale']}`, body);

    await ctx.dispatch('setToken', data);
  },

  async signinWithSocial (ctx, { provider, body }: Types.SignInSocialPayload) {
    try {
      const { data } = await this.$axios.post(`/users/signin/social/${provider}?lang=${ctx.rootGetters['lang/getLocale']}`, body);
      await ctx.dispatch('setToken', data);
    } catch (e) {
      return activationError(e);
    }
  },

  async signupWithEmail (ctx, body: Types.SignUpEmailPayload) {
    var authToken;
    try {
      const res = await this.$axios.post(`/users/signup/email?lang=${body.lang}`, body);
      authToken = res.data;
    } catch (e) {
      const error = e.response?.status === 422 ? validationError(e) : activationError(e);

      ctx.commit('SET_LOGIN_ERROR', error);
      return error;
    }

    await ctx.dispatch('setToken', authToken);
    await ctx.dispatch('loadProfile');
  },

  async signupWithSocial (ctx, { provider, body }: Types.SignUpSocialPayload) {
    var authToken;
    try {
      const res = await this.$axios.post(`/users/signup/social/${provider}?lang=${body.lang}`, body);
      authToken = res.data;
    } catch (e) {
      return activationError(e);
    }

    await ctx.dispatch('setToken', authToken);
    await ctx.dispatch('loadProfile');
  },

  loadToken () {
    const token = this.$cookies.get('token');
    if (!token) return;
    this.$axios.setToken(token, 'Bearer');
    return token;
  },

  setToken (ctx, data: any) {
    this.$cookies.set('token', data.auth.access_token);
    this.$axios.setToken(data.auth.access_token, 'Bearer');
  },

  async loadProfile (ctx: any, { useCache = false } = {}) {
    ctx.commit('SET_USER_IS_LOADING', true);

    if (useCache === true) {
      if (ctx.state.me !== null) {
        if (
          !objectIsEmpty(ctx.state.me) &&
          ctx.state.me.id === this.$cookies.get('currentUserId')
        ) {
          ctx.commit('SET_USER_IS_LOADING', false);
          return ctx.state.me;
        }
      }
    }

    try {
      const { data: userData } = await this.$axios.get(`/users/me?lang=${ctx.rootGetters['lang/getLocale']}`);

      ctx.commit('SET_ME', userData);
      this.$cookies.set('currentUserId', userData.id);

      ctx.commit('SET_USER_IS_LOADING', false);

      return userData;
    } catch (e) {
      if (!e.response || e.response.status !== 401) throw e;

      await ctx.dispatch('signout');
      return null;
    }
  },
  async newsletterHasBeenSeen (ctx, payload) {
    try {
      const response = await this.$axios.post(`public/onpiste/newsletter`, { email: payload.userEmail });
      this.$cookies.set('newsletterHasBeenSeen', true);
      ctx.commit('STORE_NEWSLETTER_HAS_BEEN_SEEN', true);
      return response.status;
    } catch (e) {
      return e.response.status;
    }
  },

  async updateProfile (ctx, body) {
    await this.$axios.put(`/users/me?lang=${ctx.rootGetters['lang/getLocale']}`, body);
    await ctx.dispatch('loadProfile');
  },

  async updateFavoriteActivites (ctx, body) {
    await this.$axios.put(`/users/me/favorite_sports?lang=${ctx.rootGetters['lang/getLocale']}`, body);
    await ctx.dispatch('loadProfile');
  },

  async sendPasswordResetLink (ctx, body) {
    await this.$axios.post(`/users/password-reset?lang=${body.lang}`, body);
  },

  async sendEmailReset (ctx, body) {
    await this.$axios.post(`/users/me/email-reset?lang=${body.lang}`, body);
  },

  async updatePassword (ctx, body) {
    await this.$axios.put(`/accounts/me/password?lang=${ctx.rootGetters['lang/getLocale']}`, body);
  },

  async resetPassword (ctx, body) {
    await this.$axios.post(`/users/password-reset/confirm?lang=${body.lang}`, body);
    if (ctx.state.me) {
      await ctx.dispatch('signout');
    }
  },

  async deleteAccount (ctx, body) {
    await this.$axios.post(`/users/me/delete?lang=${body.lang}`, body);
    await ctx.dispatch('signout');
  },

  async signout (ctx) {
    this.$social.signOutIfSignedIn();
    this.$axios.setToken(false);
    this.$cookies.remove('token');
    this.$cookies.remove('currentUserId');
    ctx.commit('activities/RESET_ALL', null, { root: true });
    const route: any = this.localePath('/signin');
    this.$router.push(route);
    // TODO: keep preferences stored by user instead of reseting them on signout
    // TODO: Find a way to prevent brief disappearance of data in the UI until navigation occurs
    await ctx.dispatch('_reset');
  },

  async contactOnPiste (ctx, payload) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const formData = new FormData();
    if (payload.destination_id) formData.append('destination_id', payload.destination_id);
    formData.append('subject_id', payload.subject_id);
    formData.append('message', payload.message);
    formData.append('email', payload.email);
    formData.append('firstname', payload.firstname);
    formData.append('lastname', payload.lastname);
    formData.append('country', payload.country);
    if (payload.phone) formData.append('phone', payload.phone);
    payload.attachments.forEach((a: string) => {
      formData.append('attachments[]', a);
    });

    await this.$axios.post(`/onpiste/make-contact?lang=${ctx.rootGetters['lang/getLocale']}`, formData, { headers });
  },

  async sendPreferredUnit () {
    await this.$axios.put('/users/me/preferred_unit', { preferred_unit: this.$cookies.get('unit') || 'metric' });
  },
};
