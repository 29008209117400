

































































import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';

import UserOutline from '~/components/icons/UserOutline.vue';
import SettingsSolid from '~/components/icons/SettingsSolid.vue';
import MailOutline from '~/components/icons/MailOutline.vue';
import ChevronRightSolid from '~/components/icons/ChevronRightSolid.vue';
import DotsVerticalSolid from '~/components/icons/DotsVerticalSolid.vue';
import Dropdown from '~/components/partials/Dropdown.vue';

type MenuItem = { link: string, name: string, icon: Function };

@Component({
  components: { ChevronRightSolid, DotsVerticalSolid, Dropdown },
  computed: {
    ...mapState('lang', [
      'locale',
    ]),
  },
})
export default class TopMenu extends Vue {
  locale: string;

  $refs: {
    settingsMenuBtn: HTMLButtonElement,
    chevron: Vue;
  };

  /*
    Returns the breadcrumb (needs to be reactive so this.locale isn't
    undefined at first)
  */
  get menuItems () {
    return [
      {
        link: this.locale === 'fr' ? `/settings/profile` : `/${this.locale}/settings/profile`,
        name: this.$t('settingsMenu.menuItems.profile'),
        icon: UserOutline,
      },
      {
        link: this.locale === 'fr' ? `/settings/account` : `/${this.locale}/settings/account`,
        name: this.$t('settingsMenu.menuItems.account'),
        icon: SettingsSolid,
      },
      {
        link: this.locale === 'fr' ? `/settings/email` : `/${this.locale}/settings/email`,
        name: this.$t('settingsMenu.menuItems.email'),
        icon: MailOutline,
      },
    ];
  }

  /* Returns the active element of this.menuItems representing active page */
  get actualLink () {
    return this.menuItems.find((item: MenuItem) => item.link === this.$route.path) || this.menuItems[0];
  }

  /* Returns the inactive pages */
  get otherLinks () {
    return this.menuItems.filter((item: MenuItem) => item.link !== this.$route.path) || [];
  }

  /* Handle clicks of user inside of the dropdown btn to close/open the dropdown */
  handleClick (val: boolean) {
    if (!val) this.bluredBtn();
    else this.focusedBtn();
  }

  /* Will apply the style of the dropdown when user clicks inside */
  focusedBtn () {
    this.$refs.settingsMenuBtn.classList.remove('border-gray-200');
    this.$refs.settingsMenuBtn.classList.add('border-gray-default');
    this.$refs.chevron.$el.classList.add('-rotate-180', '-rotate-90');
  }

  /* Will apply the style of the dropdown when user clicks outside */
  bluredBtn () {
    this.$refs.settingsMenuBtn.classList.remove('border-gray-default');
    this.$refs.chevron.$el.classList.remove('-rotate-180', '-rotate-90');
  }

  /* Returns boolean to check if template element represents actual page */
  linkIsActualRoute (link: string) {
    return link === this.$route.path;
  }
}
